<template>
    <div class="alert" :class="color">
        <span v-html="this.text"></span>
    </div>
</template>

<script>

export default {
    name: 'AlertItem',
    props: ['text', 'color'],
    mounted(){
    }
}
</script>

<style scoped>
.alert {
    position: fixed;
    right: 20px;
    bottom: 20px;
    width: auto;
    height: auto;
    padding: 20px 40px;
    background-color: #fff;
    border-radius: 10px;
    z-index: 999;
    box-shadow: 0 1px 6px rgba(0,0,0,.15);
}

.alert.red {background-color: #e24444;color: #fff;font-weight: 600;}
.alert.yellow {background-color: #dbd81c;color: #000;font-weight: 600;}
</style>