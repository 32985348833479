<template>
	<div class="topbar" :class="{active: $store.state.navbar}">
		<div class="title">
			<i class="fa-solid" :class="$store.state.system.icon"></i>
			&nbsp;{{$store.state.system.title}}
		</div>
		<div class="userbox">
			<div @click="$store.state.notif = !$store.state.notif" v-if="nots.length == 0" class="icon"><i class="fa-regular fa-bell"></i></div>
			<div @click="$store.state.notif = !$store.state.notif" v-if="nots.length > 0" class="icon"><i class="fa-solid fa-bell fa-shake"></i></div>
			<Transition name="popup">
				<Notifications v-if="$store.state.notif == true" :data="nots" @reload="getNot" />
			</Transition>
			<router-link v-for="(e, i) in $store.state.system.modulesE" :key="i" :to="{path: '/uploads', name: 'UploadsPage'}">
				<div class="icon" v-if="e.name == 'Uploads'"><i class="fa-solid fa-cloud-arrow-up"></i></div>
			</router-link>
			<div class="boxhover">
				<div class="name">{{ $store.state.user.user}}</div>
				<div class="image" :style="{backgroundImage: 'url('+$store.state.user.image+')' }"></div>
				<div class="actions">
					<a href="https://ppanel.pauloferraco.com.br/changelog" target="_blank" rel="noopener noreferrer"><button><i class="fa-solid fa-circle-plus"></i>&nbsp;Novidades PowerPanel</button></a>
					<button @click="modUC = true"><i class="fa-solid fa-gears"></i>&nbsp;&nbsp;Configurações</button>
					<button @click="logout"><i class="fa-solid fa-right-from-bracket"></i>&nbsp;&nbsp;Sair</button>
				</div>
			</div>
		</div>
	</div>
	<Transition name="bounce">
		<ConfigUser v-if="modUC == true" @close="modUC = false" />
	</Transition>
</template>

<script>
import axios from 'axios'
import ConfigUser from './utils/modals/modConfigUser.vue'
import Notifications from './notifications.vue'

export default {
	name: 'TopBar',
	data() {
		return {
			modUC: false,
			not: false,
			nots: []
		}
	},
	methods: {
		async logout() {
			const req = await axios.get('logout')
			if(req.status == 200){
				this.$store.state.logged = false
				window.localStorage.setItem('logged', false)
				window.localStorage.setItem('user', '')
			}
		},
		async getNot(){
			const req = await axios.get('async_util/notification/get')
			this.nots = req.data
		}
	},
	mounted () {
		this.getNot();
		this.$store.dispatch('modExtra')
	},
	components: {
		ConfigUser,
		Notifications
	},
}
</script>

<style scoped>

.topbar {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 66px;
	background-color: var(--color1);
	padding: 0 20px 0 110px;
	display: flex;
	justify-content: space-between;
	z-index: 998;
	transition: all .5s ease;
}
.topbar.active {padding-left: calc(15% + 100px);}

.topbar .title {
	width: auto;
	height: 20px;
	line-height: 22px;
	font-size: 15px;
	font-weight: 600;
	color: #fff;
	margin-top: 22px;
	user-select: none;
}

.topbar .userbox {
	display: flex;
	justify-content: flex-end;
	position: relative;
	width: 50%;
	height: 40px;
	margin-top: 12px;
	user-select: none;
}

.userbox .icon {
	width: 20px;
	height: 20px;
	text-align: center;
	line-height: 20px;
	font-size: 16px;
	color: #fff;
	margin: 11px 22px 0 0;
	cursor: pointer;
	transition: all .3s linear;
	background-color: ;
}
.userbox .icon:hover {color: #ccc;}

.topbar .userbox .name {
	width: auto;
	line-height: 20px;
	font-size: 14px;
	font-weight: 600;
	color: #fff;
	margin: 10px 10px 0 0;
}

.topbar .userbox .image {
	width: 40px;
	height: 40px;
	border-radius: 40px;
	background-color: #ccc;
	background-size: cover;
	background-position: center;
}

.userbox .boxhover {width: auto;display: flex;justify-content: space-evenly;padding: 2px 10px;}

.topbar .actions {
	position: absolute;
	right: 0;
	top: 44px;
	width: auto;
	height: auto;
	background-color: #fff;
	border-radius: 10px;
	box-shadow: 0 2px 8px rgba(0,0,0,.2);
	padding: 8px 0;
	color: #444;
	opacity: 0;
	visibility: hidden;
	transition: all .3s ease-in-out;
}
.topbar .userbox .boxhover:hover .actions  {opacity: 1;visibility: visible;}

.topbar .actions button {
	float: left;
	width: 100%;
	height: 40px;
	margin: 2px 0 0 0;
	cursor: pointer;
	text-align: left;
	padding: 0 10px;
	line-height: 40px;
	font-size: 14px;
	font-weight: 500;
	transition: all .3s ease;
}
.topbar .actions button:hover {background-color: var(--color1);color: #fff;}

.topbar .actions button i {width: 20px;height: 20px;}

@media only screen and (max-width: 900px) {
	.topbar {padding: 0 0 0 55px !important;}
	.topbar.active {padding: 0;}
	.topbar .title {display: none;}
	.topbar .userbox {width: 100% !important;}
}

@media only screen 
	and (min-width: 900px) 
	and (max-width: 1000px) {
	.topbar.active {padding-left: 110px;}
}

@media only screen 
	and (min-width: 1000px) 
	and (max-width: 1400px) {
	.topbar {padding-left: 100px;}
	.topbar.active {padding-left: calc(20% + 100px);}
	.topbar .userbox {width: 50%;}
}
</style>